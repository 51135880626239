<template>
    <v-container fluid>
        <PageLayout>
            <template v-slot:side>
                <v-icon color="primary" @click="filter_show===true ? filter_show=false : filter_show=true">mdi-filter-plus</v-icon>
                <v-list class="filter_left mt-5">
                    <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                        </template>

                        <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link :class="item.router === $route.name ? 'active' : ''">
                            <v-list-item-title class="cursor-pointer" @click="sendRoute(item.router)" v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-list class="filter_left" v-if="Additional.length > 0">
                    <v-list-group :ripple="false" v-model="group2" :group="'group2'" class="px-0">
                        <template v-slot:activator class="px-0">
                            <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                        </template>
                        <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" link>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </template>
            <template>
                <v-form @submit.prevent="getTopics('search')">
                    <ValidationObserver class="mb-3" tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <TabNav/>
                        <h1>{{ filter_show ? $t('search_topic') : $t('menu_my_topics') }}</h1>
                        <v-row v-if="filter_show" class="mt-3 mb-3">
                            <v-col class="py-0" cols="12" sm="10">
                                <ValidationProvider ref="topic" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field v-model="topic" type="text"
                                                  :error-messages="errors"
                                                  :label="$t('topic_name')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems"
                                          item-text="name" item-value="id"
                                          :label="$t('active')"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :disabled="loading"
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                    <v-date-picker ref="pickerDateContract" v-model="date"
                                                   :locale="lang"
                                                   @change="dateDialog = false">
                                    </v-date-picker>
                                </v-dialog>
                                <ValidationProvider ref="dateContract" rules="date_format"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="date" :error-messages="errors"
                                                  :error="!valid"
                                                  :label="$t('date_created')"
                                                  readonly
                                                  @click.stop="dateDialog = !dateDialog"
                                                  @click:clear="date = null"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="client" rules="min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="client" :items="clientItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="clientSearching"
                                                    item-text="name" item-value="id"
                                                    :loading="loadingClients"
                                                    :no-data-text="clientSearching ? $t('nothing_found_by',{'search': clientSearching}) : $t('nothing_found_name')"
                                                    :label="$t('client')"
                                                    @click:clear="clientItems=[]"
                                                    @click="clearClients"
                                                    @click:append="redirectToClient(client)"
                                                    autocomplete="off"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    :disabled="loading"
                                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.phone"></span>
                                                    </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        <span class="ml-2 hidden-is-empty"
                                                              v-text="item.address_legal"></span>
                                                    </span>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pb-2" cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :disabled="loading"
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </v-col>
                            <v-col  cols="12" sm="9" md="10"
                                   class="py-0 pb-2 d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary" class="infinity_button">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </ValidationObserver>
                </v-form>
                <v-data-table :headers="filteredHeaders" :items="topics" :options.sync="options"
                              :page.sync="page" :items-per-page="perPage" :server-items-length="totalTopics"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')"
                              hide-default-footer single-expand item-key="id" show-expand
                              v-model="selected"
                              :single-select="singleSelect"
                              show-select
                              @toggle-select-all="selectAllToggle"
                              :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                              class="main-data-table elevation-0"
                              :item-class="rowClass"
                >
                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                        <v-simple-checkbox
                            :value="isSelected"
                            :ripple="false"
                            :readonly="item.number_deal !== ''"
                            :disabled="item.number_deal !== ''"
                            @input="select($event)"
                        ></v-simple-checkbox>
                    </template>
                    <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                        <thead>
                        <tr class="rowHeadClass myTopics">
                            <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span v-if="h.value === 'data-table-select'">
                                             <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon color="primary"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="openDialogDealToTopic"
                                                    >mdi-briefcase-variant</v-icon>
                                                </template>
                                                <span>{{ $t('assign_new_client') }}</span>
                                             </v-tooltip>
                                        </span>
                                <span v-else>{{ h.text }}</span>
                            </th>
                        </tr>
                        </thead>
                    </template>
                    <template v-slot:item.title="{ item }">
                        <div>
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                                    <span @click="openChat(item)"
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          class="cursor-pointer font_weight_600">{{ item.title }}</span>
                                        </template>
                                        <span>{{ $t('open_chat') }}</span>
                                    </v-tooltip>

                                    <v-icon v-if="hover" class="ml-2" :title="$t('edit')" @click="openTopicDialog(item)">
                                        mdi-pencil mdi-18px
                                    </v-icon>

                                </div>
                            </v-hover>
                        </div>


                    </template>
                    <template v-slot:item.number_deal="{ item }">
                        <div class="cursor-pointer" @click="showDeal(item.deal_uuid)">{{ item.number_deal }}</div>
                    </template>
                    <template v-slot:item.client.name="{ item }">
                        <div>
                            <v-list v-if="item.company" subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                        <img v-if="item.client.photo !== '/img/avatar.png'" :src="item.client.photo" :alt="item.client.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.client.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.client.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        <v-edit-dialog :return-value.sync="item.active" large persistent
                                       @save="activeTopic(item)" :save-text="$t('save')"
                                       :cancel-text="$t('cancel')">
                            <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no') }}</div>
                            <template v-slot:input>
                                <v-select v-model="item.active" :items="itemYN" item-text="name"
                                          item-value="id"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :disabled="loading"
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="pt-5 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:expanded-item="{ headers, item}">
                        <td :colspan="headers.length">
                            <v-row justify="center" class="px-3">
                                <v-col cols="12" class="justify-start">
                                    <v-list subheader two-line class="background_none py-0">
                                        <v-list-item-subtitle>{{ $t('managers') }}:</v-list-item-subtitle>
                                        <v-list-item v-for="(admin, index) in item.admins" :key="'manager' + index">
                                            <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                <img v-if="admin.photo !== '/img/avatar.png' && admin.photo !== 'null'" :src="admin.photo" :alt="admin.name">
                                                <v-icon v-else color="primary" size="22">
                                                    ${{ 'settingsIcon' }}
                                                </v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content class="py-0 title_subtitle">
                                                <v-list-item-title v-text="admin.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="formatPhoneNumber(admin.phone)"></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="deleteAdmin(item, admin)" icon>
                                                            <v-icon>mdi-delete-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>{{ $t('delete') }}</span>
                                                </v-tooltip>
                                                <v-icon></v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-action>
                                                <v-btn
                                                    color="primary"
                                                    @click="formAddAdmin(item)"
                                                    class="infinity_button"
                                                >
                                                    {{ $t('add_manager') }}
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
                <v-card flat class="background_color_transparent">
                    <v-card-actions class="pt-0 justify-end">
                        <v-pagination v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                    </v-card-actions>
                </v-card>
            </template>
        </PageLayout>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialog_add_admin"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('add_manager') }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeFormAddAdmin()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>

                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="admin" rules="min:1"
                                            v-slot="{ errors, valid }">
                            <v-autocomplete v-model="admin" :items="adminItems"
                                            :error-messages="errors" :error="!valid"
                                            :search-input.sync="adminSearching"
                                            item-text="name" item-value="uuid"
                                            append-icon="mdi-location-enter"
                                            :loading="loadingAdmins" :disabled="loading"
                                            :no-data-text="adminSearching ? $t('nothing_found_by',{'search': adminSearching}) : $t('nothing_found_name')"
                                            :label="$t('manager')"
                                            @click:clear="adminItems=[]"
                                            @click="clearAdmins"
                                            @click:append="redirectToAdmin(admin)"
                                            clearable autocomplete="off"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            hide-details
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                            >
                                <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.phone"></span>
                                        </span>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.address_legal"></span>
                                        </span>
                                </template>
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="addAdmin()"
                    >{{ $t('add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            v-model="editTopicDialog"
            width="500"
            class="dialog_body">

            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('topic_editing') }}</span>
                        </div>
                        <div>
                            <v-icon @click="closeTopicDialog()" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text class="pa-2 pb-0">
                        <v-col cols="12">
                            <ValidationProvider ref="topic_title" rules="required|min:5|max:250"
                                                v-slot="{ errors, valid }">
                                <v-textarea v-model="topic_title" type="text"
                                            rows="3"
                                            outlined
                                            :error-messages="errors" :error="!valid"
                                            :disabled="loading"
                                            clearable
                                            class="ma-0 pa-0 my-0 py-0 input_textarea"
                                            no-details
                                            hide-details
                                            full-width>
                                </v-textarea>
                            </ValidationProvider>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="editTopic()">
                            {{ $t('edit') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="dialogDeal"
            class="dialog_body"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ $t('add_deal') }}</span>
                    </div>
                    <div>
                        <v-icon @click="closeDealToTopic()" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text class="pa-2 pb-0">
                    <v-col cols="12">
                        <ValidationProvider ref="deal" rules="min:1"
                                            v-slot="{ errors, valid }">
                            <v-autocomplete v-model="deal" :items="dealItems"
                                            :error-messages="errors" :error="!valid"
                                            :search-input.sync="dealSearching"
                                            item-text="number" item-value="id"
                                            append-icon="mdi-location-enter"
                                            :loading="loadingDeals" :disabled="loading"
                                            :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                            :label="$t('deal.self')"
                                            @click:clear="dealItems=[]"
                                            @click="clearDeals"
                                            clearable autocomplete="off"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            hide-details
                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                            >
                                <template v-slot:item="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                </template>
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.number"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.admin.name"></span>
                                            </span>
                                </template>
                            </v-autocomplete>
                        </ValidationProvider>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="addDealToTopic()"
                    >{{ $t('add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>

</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import debounce from "lodash/debounce";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TabNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: "MyTopics",
    components: {
        PageLayout,
        ValidationProvider,
        ValidationObserver,
        TabNav
    },
    data() {
        return {
            disabledCount: 0,
            singleSelect: false,
            selected: [],
            deal: null,
            dealItems: [],
            dealSearching: null,
            loadingDeals: false,
            dialogDeal: false,

            editTopicDialog: false,
            topic_title: null,
            topics_index: null,
            topic_item: {},
            topic_uuid: null,
            dialog_add_admin: false,
            date: null,
            dateDialog: false,
            client: null,
            clientItems: [],
            clientSearching: null,
            loadingClients: false,

            admin: null,
            adminItems: [],
            adminSearching: null,
            loadingAdmins: false,

            topic: null,
            topics: [],
            topicItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            sortBy: "updated_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalTopics: 0,
            options: {},
            loading: false,
            itemYN: [
                {
                    id: 0,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            headers: [

                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "title"
                },
                {
                    text: this.$t('number_deal'),
                    align: "center",
                    sortable: false,
                    value: "number_deal"
                },
                {
                    text: this.$t('client'),
                    align: "left",
                    sortable: true,
                    value: "client.name"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "active",
                    width: 110,
                },
                {
                    text: '',
                    value: 'data-table-expand',
                    width: 40,
                    class: "handle",
                },
            ],
            system_filters: [
                {'title': this.$t('new_appeals'), 'count': '10', 'router': 'topics'},
                {'title': this.$t('my_appeals'), 'count': '0', 'router': 'my_topic'},
            ],
            Additional: [],
            group1: true,
            group2: true,
            filter_show: false,
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),

        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getTopics()
            },
            deep: false
        },
        clientSearching: debounce(function (val) {
            if (val && !this.client) {
                this.getClients(val)
            }
        }, 500),
        adminSearching: debounce(function (val) {
            if (val && !this.admin) {
                this.getAdmins(val)
            }
        }, 500),
        dealSearching: debounce(function (val) {
            if (val) {
                this.getDeals(val)
            }
        }, 500),
    },
    methods: {
        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass myTopics' : 'rowMobiClass';
        },
        selectAllToggle(props) {
            if (this.selected.length !== (this.topics.length - this.disabledCount)) {
                this.selected = [];
                const self = this;
                props.items.forEach(item => {
                    if (!item.number_deal) {
                        self.selected.push(item);
                    }
                });
            } else this.selected = [];
        },
        showDeal(uuid) {
            this.$router.push({
                name: 'deal.show',
                params: {
                    id: uuid
                }
            })
        },
        async getDeals(str) {
            this.loadingDeal = true
            let params = {};
            params.filter = 'not_topic'
            if (str !== 'undefined') {
                params.number = str;
            }

            await this.$http
                .get("admin/deal", {
                    params: params,
                })
                .then(res => {
                    this.dealItems = res.body.data;
                })
                .catch(err => {
                    this.dealItems = []
                    this.$toastr.error(this.$t('failed_to_get_deals'))
                })
                .finally(end => {
                    this.loadingDeal = false
                })
        },
        clearDeals() {
            if (!this.deal) {
                this.dealItems = []
            }
        },
        closeDealToTopic() {
            this.dialogDeal = false
            this.deal = null
            this.dealItems = []
            this.dealSearching = null

        },
        async addDealToTopic() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            this.selected.forEach(function callback(item, key) {
                formData.append(`topics_uuid[${key}]`, item.uuid)
            });

            if (this.deal) {
                formData.append('deal', this.deal)
            }
            // Add
            await this.$http
                .post('admin/my_topic/deal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    this.closeDealToTopic();
                    this.selected = [];
                    this.getTopics();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        openDialogDealToTopic() {
            this.deal = null
            this.dealItems = []
            this.dealSearching = null
            this.dialogDeal = true
        },


        closeTopicDialog() {
            this.editTopicDialog = false;
            this.topic_title = null;
            this.topic_uuid = null;
            this.topics_index = null;
        },
        openTopicDialog(item) {
            this.topics_index = this.topics.indexOf(item)
            this.topic_title = item.title;
            this.topic_uuid = item.uuid;
            this.editTopicDialog = true;
        },
        async editTopic() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.topic_uuid) {
                formData.append('topic_uuid', this.topic_uuid)
            }
            if (this.topic_title) {
                formData.append('topic_title', this.topic_title)
            }


            // Add
            await this.$http
                .put('admin/topic/title', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('manager_has_been_added'))
                    this.topics[this.topics_index].title = this.topic_title;
                    this.closeTopicDialog();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('manager_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        formAddAdmin(item) {
            this.topic_item = item
            this.topic_uuid = item.uuid
            this.dialog_add_admin = true
        },
        closeFormAddAdmin() {
            this.topic_uuid = null
            this.dialog_add_admin = false
            this.admin = null
            this.topic_item = {}
        },
        openChat(item) {
            this.$router.push({
                name: 'topic.message',
                params: {
                    id: item.uuid
                }
            })
        },
        async getTopics(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.topic) {
                params.topic = this.topic;
            }
            if (this.date) {
                params.date = this.date;
            }

            if (this.client) {
                if (this.client.id) {
                    params.client = this.client.id
                } else {
                    params.client = this.client
                }
            }
            params.active = this.active

            await this.$http
                .get("admin/my_topic", {
                    params: params,
                })
                .then(res => {
                    this.topics = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalTopics = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                    this.topics.map(item => {
                        if (item.number_deal !== '') {
                            this.disabledCount = this.disabledCount + 1
                        }
                    })
                })
                .catch(err => {
                    this.topics = []

                    this.totalTopics = 0
                    this.$toastr.error(this.$t('failed_to_get_list_topics'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async activeTopic(item) {
            this.loading = true;
            let params = {};
            if (item.active) {
                params.active = 1
            } else {
                params.active = 0
            }
            await this.$http
                .put(`admin/topic/active/${item.id}`, params)
                .then(res => {
                    if (params.active) {
                        this.$toastr.success(this.$t('topic_is_open'))
                    } else {
                        this.$toastr.success(this.$t('topic_is_closed'))
                    }

                })
                .catch(err => {
                    if (params.active) {
                        this.$toastr.error(this.$t('topic_was_not_open'))
                    } else {
                        this.$toastr.error(this.$t('topic_was_not_closed'))
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.client = str
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then(res => {
                        this.clientItems = res.body.data
                    })
                    .catch(err => {
                        this.clientItems = []
                    })
                    .finally(end => {
                        this.loadingClients = false
                    })
            }
        },
        clearClients() {
            if (!this.client) {
                this.clientItems = []
            }
        },
        redirectToClient(val) {
            if (val) {
                this.$router.push({
                    name: 'client.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getAdmins(str) {
            if (str) {
                this.loadingAdmins = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.admin = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.adminItems = res.body.data
                    })
                    .catch(err => {
                        this.adminItems = []
                    })
                    .finally(end => {
                        this.loadingAdmins = false
                    })
            }
        },
        clearAdmins() {
            if (!this.admin) {
                this.adminItems = []
            }
        },
        redirectToAdmin(val) {
            if (val) {
                this.$router.push({
                    name: 'admin.profile',
                    params: {
                        id: val
                    }
                })
            }
        },

        async addAdmin() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.topic_uuid) {
                formData.append('topic_uuid', this.topic_uuid)
            }
            if (this.admin) {
                if (this.admin.uuid) {
                    formData.append('admin_uuid', this.admin.uuid)
                } else {
                    formData.append('admin_uuid', this.admin)
                }
            }


            // Add
            await this.$http
                .post('admin/topic_manager', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('manager_has_been_added'))

                    if (res && res.body && res.body.data && res.body.data.id) {
                        let idx = _this.topics.indexOf(_this.topic_item);
                        if (idx !== -1) {
                            let idy = _this.topics[idx]['admins'].push(res.body.data);
                        }
                    }
                    this.closeFormAddAdmin();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('manager_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async deleteAdmin(item, admin) {

            if (confirm(this.$t('delete_manager'))) {
                var _this = this
                this.loading = true;
                let params = {};
                params.admin_uuid = admin.uuid
                await this.$http
                    .delete(`admin/topic_manager/${item.uuid}`, {
                        params: params,
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('manager_has_been_deleted'))
                        let idx = this.topics.indexOf(item);
                        if (idx !== -1) {
                            let idy = this.topics[idx]['admins'].indexOf(admin);
                            if (idy !== -1) {
                                this.topics[idx]['admins'].splice(idy, 1);
                            }
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('manager_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
